// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-detail-js": () => import("./../src/pages/jobs/detail.js" /* webpackChunkName: "component---src-pages-jobs-detail-js" */),
  "component---src-pages-jobs-index-js": () => import("./../src/pages/jobs/index.js" /* webpackChunkName: "component---src-pages-jobs-index-js" */),
  "component---src-pages-news-detail-js": () => import("./../src/pages/news/detail.js" /* webpackChunkName: "component---src-pages-news-detail-js" */),
  "component---src-pages-news-index-js": () => import("./../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */)
}

