import { GlobalActions } from "./actions"
export const whenResize = (state, action) => {
  if (action.type === GlobalActions.ON_RESIZE) {
    return { ...state, isMobile: window.isMobile() }
  } else {
    return state
  }
}

export const BannerObserver = (state, action) => {}
