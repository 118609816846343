/* eslint-disable */
import classNames from "classnames"
import { createStore } from "redux"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Slider from "./react-slick"

import { window, document } from "browser-monads"

export { default as SEQ } from "./seq-exec"

export { window, document, Slider }

export const $c = name => {
  return (condition, Classes) => {
    const Klss = typeof Classes === "string" ? [Classes] : [...Classes]
    const exp = { animated: condition }
    Klss.map(c => {
      exp[c] = condition
    })
    return classNames(name, exp)
  }
}

export const defaultIntl = {
  formatMessage: () => "",
}

export const _isMobile = () => {
  let check = false
  ;(function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      ) ||
      window.document.documentElement.clientWidth < 1024
    ) {
      check = true
    }
  })(window.navigator.userAgent || window.navigator.vendor || window.opera)
  return check
}
const initState = {
  isMobile: _isMobile(),
  Ready: false,
  currentBanner: 0,
}

export const EventBus = createStore((state, action) => {
  if (action.type === "resize") {
    return { ...state, isMobile: _isMobile() }
  }
  if (action.type === "banner_over") {
    return { ...state, invert: action.data }
  }
  if (action.type === "YouTuBeReady") {
    return { ...state, Ready: true }
  }
  if (action.type === "ChangeBanner") {
    return { ...state, currentBanner: action.data }
  }

  return state
}, initState)

export const Responsive = ({ mobile, desktop, intl }) => {
  // if is mobile not render as sliders
  const Mobile = mobile
  const DeskTop = desktop
  const [isMobile, setIsMobile] = useState(window.isMobile())
  const unsubscribe = EventBus.subscribe((e, s) => {
    const state = EventBus.getState()
    setIsMobile(state.isMobile)
  })
  useEffect(() => {
    return () => {
      unsubscribe()
    }
  }, [isMobile])

  return <>{isMobile ? <Mobile intl={intl} /> : <DeskTop intl={intl} />}</>
}

Responsive.PropsType = {
  mobile: PropTypes.element.isRequired,
  desktop: PropTypes.element.isRequired,
}

export const AreaData = [
  "Burnaby",
  "West Vancouver",
  "White Rock",
  "Richmond",
  "North Vancouver",
  "Delta",
  "Vancouver",
  "Port Moody",
  "Maple Ridge",
  "Coquitlam",
  "Port Coquitlam",
  "Anmore",
  "Surrey",
  "Langley",
  "New Westminster",
]

export const TransportData = {
  en: ["Car (self-provided)", "Motorbike (self-provided)", "Electric Motorbike (rent)"],
  // TODO:待替换为法语
  fr: ["Car (self-provided)", "Motorbike (self-provided)", "Electric Motorbike (rent)"],
  zh: ["汽车（自备)", "摩托车（自备）", "电动自行车（租赁）"],
}

export const OpenHours = [
  "Mon 11am - 5pm",
  "Mon 5pm - 11pm",
  "Tue 11am - 5pm",
  "Wed 11am - 5pm",
  "Wed 5pm - 11pm",
  "Thu 11am - 5pm",
  "Fri 11am - 5pm",
  "Fri 5pm - 11pm",
  "Sat 11am - 5pm",
  "Sun 11am - 5pm",
  "Sun 5pm - 11pm",
  "Tue 5am - 11pm",
  "Thu 5pm - 11pm",
  "Sat 5pm - 11pm",
]

export const UseQuery = () => {
  return new window.URLSearchParams(window.location.search)
}

export const dateFormat = time => {
  const d = new Date(time)
  const mon = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][d.getMonth()]
  const date = d.getDate()
  const year = d.getFullYear()
  return `${mon} ${date}, ${year}`
}

export const isAu = () => {
  return window.location.host.includes('au');
}

export const getEmail = () => {
  if (isAu()) return "enquiry@fantuan.com.au";
  else return "feedback@fantuan.ca"
}
// 获取路由参数
export const getQuery = () => {
  const search = window.location.search
  const query = {}
  if (search) {
    const queryArr = search.split("?")[1].split("&")
    queryArr.map(item => {
      const arr = item.split("=")
      query[arr[0]] = arr[1]
    })
  }
  
  return query;
}
// 获取路由国家
export const getNowCountry = () => {
  const countryListMap = ['ca', 'us', 'au', 'uk'];
  const country = getQuery().country
  if (countryListMap.includes(country)) {
    return country;
  } else {
    return 'ca'
  }
}