import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ lang, title }) {
  const siteMetadata = {
    en: {
      Title: "Fantuan Delivery the Best Asian Food Delivery",
      Keyword:
        "delivery, food, food delivery, chinese food, korean food, japanese food, asian food, bubble tea, dessert, restaurant, order, online order, dinner, promotion, restaurant near me",
      Description:
        "Order food with Fantuan Delivery. Get the best Asian food delivered to your door from your favorite local restaurants. Everyday promotion.",
    },
    fr: {
      Title: "Fantuan Livraison la meilleure livraison de plats asiatiques",
      Keyword:
        "livraison, aliments, livraison de nourriture, nourriture chinoise, nourriture coréenne, nourriture japonaise, nourriture asiatique, Bubble Tea, dessert, restaurant, commande, commande en ligne, dîner, promotion, Restaurant près de chez moi",
      Description:
        "Commander de la nourriture avec Fantuan Livraison. Obtenez la meilleure cuisine asiatique livrée à votre porte depuis vos restaurants locaux préférés. Promotion quotidienne.",
    },
    zh: {
      Title: "饭团外卖 Fantuan Delivery_美食外卖_餐厅自取_商城跑腿",
      Keyword: "外卖送餐，中餐美食，餐厅自取，跑腿代购，生活服务，优惠，奶茶，甜品，韩餐，日料，寿司，炸鸡，川菜，粤菜，早茶",
      Description:
        "饭团外卖是北美最大的亚洲美食外卖平台，为消费者提供餐饮美食，水果生鲜，超市便利等品类的即时配送服务，包括自提和配送两种模式。足不出户，即可享受优质，便捷的外卖及跑腿服务。",
    },
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata[lang].Title}`}
      meta={[
        {
          name: "keywords",
          content: siteMetadata[lang].Keyword,
        },
        {
          name: "description",
          content: siteMetadata[lang].Description,
        },
        {
          property: "og:title",
          content: siteMetadata[lang].title,
        },
        {
          property: "og:description",
          content: siteMetadata[lang].Description,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: "Fantuan Delivery",
        },
        {
          name: "twitter:title",
          content: siteMetadata[lang].Title,
        },
        {
          name: "twitter:description",
          content: siteMetadata[lang].Description,
        },
      ]}
    >
      <script type="text/javascript">{`
        function msieversion() 
        {
            var ua = window.navigator.userAgent;
            var msie = ua.indexOf("MSIE ");
            if (/MSIE|Trident/gi.test(ua)) // If Internet Explorer, return version number
            {
              alert("The website is not supported by IE, please use other browser"+"\\n"+"饭团官网暂不支持IE浏览器，请使用其他浏览器");
            }
            return false;
        }
        msieversion()
      `}</script>

      <script>{`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '782841532169660');
        fbq('track', 'PageView');
        `}
      </script>

      <script>{`
        !function(e,t,n,g,i){e[i]=e[i]||function(){(e[i].q=e[i].q||[]).push(arguments)},n=t.createElement("script"),tag=t.getElementsByTagName("script")[0],n.async=1,n.src=('https:'==document.location.protocol?'https://':'http://')+g,tag.parentNode.insertBefore(n,tag)}(window,document,"script","assets.giocdn.com/2.1/gio.js","gio");
        gio('init','a1804462a55ad6e8', {});
        gio('send');
    `}</script>
    
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-159179564-1"></script>
    <script>{`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'UA-159179564-1');
    `}</script>
</Helmet>
)
}

SEO.defaultProps = {
  lang: "en",
  title: "Fantuan Delivery",
}

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO
