import "./src/sass/global.sass"
import { _isMobile, EventBus, window, document } from "./src/utils"
import wrapWithProvider from "./src/store"
export const wrapRootElement = wrapWithProvider

window.isMobile = _isMobile

const handleResize = e => {
  EventBus.dispatch({ type: "resize" })
}

const handleScroll = e => {
  requestAnimationFrame(() => {
    const ov = document.documentElement.scrollTop > 80
    EventBus.dispatch({ type: "banner_over", data: ov })
  })
}

const detectPixelRatiao = () => {
  if (window.devicePixelRatio < 2) {
    document.documentElement.classList.add("low-res")
  }
}
detectPixelRatiao()

window.addEventListener("resize", handleResize, false)
window.addEventListener("scroll", handleScroll, false)

window.addEventListener("load", () => {
  const youtube = document.createElement("script")
  const ga = document.createElement("script")
  const gas = document.createElement("script")
  const gaText = document.createTextNode(
    `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-159179564-1') `
  )
  youtube.setAttribute("async", true)
  youtube.setAttribute("src", "https://www.youtube.com/iframe_api")
  youtube.onload = () => {
    window.onYouTubeIframeAPIReady = function() {
      EventBus.dispatch({ type: "YouTuBeReady" })
      window.onYouTubeIframeAPIReady = null
    }
  }
  document.body.append(youtube)
  ga.setAttribute("async", true)
  ga.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=UA-159179564-1")
  gas.append(gaText)
  document.body.append(ga)
  document.body.append(gas)
})
